import { AxiosClient } from '@/app/shared/services';
import { toFormData } from "@/app/shared/services/helpers";
import Lodash from 'lodash';

const state = {
  data_forms: {
    data: [],
    isLoading: false,
  },

  data_forms_users: {
    data: [],
    isLoading: false,
  },

  modals_forms: {
    details: false,
    details_loading: false,

    confirm_deletion_form: {
      isOpen: false,
      isLoading: false,
    },
    add_form: {
      isOpen: false
    },
    user_details: {
      isOpen: false
    }
  },

  currentForm: null,
  currentFormUser: null,
};

const getters = {
  GET_FORMS: (state) => state.data_forms.data,
  IS_FORMS_LOADING: (state) => state.data_forms.isLoading,

  GET_FORM_DETAILS_MODAL_OPENED: (state) => state.modals_forms.details,
  GET_FORM_DETAILS_MODAL_LOADING: (state) => state.modals_forms.details_loading,

  // Модалка удаления
  GET_FORMS_DETAILS_MODAL_CONFIRM_DELETION: (state) => state.modals_forms.confirm_deletion_form.isOpen,
  GET_FORMS_DETAILS_MODAL_CONFIRM_DELETION_LOADING: (state) => state.modals_forms.confirm_deletion_form.isLoading,

  GET_CURRENT_FORM: (state) => state.currentForm,

  // Модалка добавления
  ADD_FORM_MODAL_OPENED: (state) => state.modals_forms.add_form.isOpen,

  // пользователи
  GET_FORMS_USERS: (state) => state.data_forms_users.data,
  IS_FORMS_USERS_LOADING: (state) => state.data_forms_users.isLoading,

  // текущий юзер
  GET_CURRENT_FORM_USER: (state) => state.currentFormUser,
  IS_FORM_USER_OPENED: (state) => state.modals_forms.user_details.isOpen
};

const mutations = {
  SET_FORMS: (state, data_forms) => {
    state.data_forms.data = data_forms;
  },
  SET_FORMS_LOADING: (state, isLoading) => {
    state.data_forms.isLoading = isLoading;
  },

  SET_FORM_DETAILS_MODAL_OPENED: (state, isOpen) => {
    state.modals_forms.details = isOpen;
  },
  SET_FORM_DETAILS_MODAL_LOADING: (state, isLoading) => {
    state.modals_forms.details_loading = isLoading;
  },

  // Модалка удаления
  SET_CONFIRM_DELETION_FORM_MODAL: (state, isOpen) => {
    state.modals_forms.confirm_deletion_form.isOpen = isOpen;
  },
  SET_CONFIRM_DELETION_FORM_MODAL_LOADING: (state, isLoading) => {
    state.modals_forms.confirm_deletion_form.isLoading = isLoading;
  },

  SET_CURRENT_FORM: (state, currentForm) => {
    state.currentForm = currentForm;
  },

  // Модалка добавления
  SET_ADD_FORM_MODAL_OPENED: (state, isOpen) => {
    state.modals_forms.add_form.isOpen = isOpen;
  },

  // пользователи
  SET_FORMS_USERS: (state, data_forms_users) => {
    state.data_forms_users.data = data_forms_users;
  },
  SET_FORMS_USERS_LOADING: (state, isLoading) => {
    state.data_forms_users.isLoading = isLoading;
  },

  // текущий юзер
  SET_CURRENT_FORM_USER: (state, currentFormUser) => {
    state.currentFormUser = currentFormUser;
  },
  SET_FORM_USER_OPENED: (state, isOpen) => {
    state.modals_forms.user_details.isOpen = isOpen;
  }
};

const actions = {
  GET_FORMS: (context, params) => {
    context.commit("SET_FORMS_LOADING", true);
    return new Promise((resolve, reject) => {
      AxiosClient.get("/forms/", { params })
        .then((data) => {
          context.commit("SET_FORMS", data.data);
          resolve();
        })
        .catch((error) => {
          reject();
        })
        .finally(() => {
          context.commit("SET_FORMS_LOADING", false);
        });
    });
  },

  GET_USERS_FORMS: (context, {params, filters}) => {
    context.commit("SET_FORMS_USERS_LOADING", true);
    return new Promise((resolve, reject) => {
      AxiosClient.post("/forms/users-data", filters, { params })
        .then((data) => {
          context.commit("SET_FORMS_USERS", data.data);
          resolve();
        })
        .catch((error) => {
          reject();
        })
        .finally(() => {
          context.commit("SET_FORMS_USERS_LOADING", false);
        });
    });
  },

  EXPORT_FORMS_USERS_TO_CSV: (context, body) => {
    context.commit("SET_FORMS_USERS_LOADING", true);
    return new Promise((resolve, reject) => {
      AxiosClient.post("/forms/users-export", body, {
        responseType: 'blob' // <== ВАЖНО! Указываем, что ответ — файл
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "users_export.csv"); // Имя файла

          let now = new Date();
          let timestamp = now.toISOString().replace(/[-T:]/g, '_').split('.')[0];
          let filename = `export_users_${timestamp}.csv`;

          link.setAttribute("download", filename);

          document.body.appendChild(link);
          link.click();
          link.remove();
          resolve();
        })
        .catch((error) => {
          console.error('Ошибка при выгрузке:', error);
          reject(error);
        })
        .finally(() => {
          context.commit("SET_FORMS_USERS_LOADING", false);
        });
    });
  },

  GET_FORM_BY_ID: (context, id) => {
    context.commit("SET_FORMS_LOADING", true);
    return new Promise((resolve, reject) => {
      AxiosClient.get(`/forms/${id}`)
        .then((data) => {
          context.commit("SET_CURRENT_FORM", data.data);
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          context.commit("SET_FORMS_LOADING", false);
        });
    });
  },

  CREATE_FORM: (context, params) => {
    context.commit("SET_FORM_DETAILS_MODAL_LOADING", true);
    return new Promise((resolve, reject) => {
      AxiosClient.post("/forms/create", params, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject();
        })
        .finally(() => {
          context.commit("SET_FORM_DETAILS_MODAL_LOADING", false);
        });
    });
  },


  DELETE_FORM: (context, id) => {
    context.commit("SET_CONFIRM_DELETION_FORM_MODAL_LOADING", true);
    return new Promise((resolve, reject) => {
      AxiosClient.delete(`/forms/delete/${id}`)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          context.commit("SET_CONFIRM_DELETION_FORM_MODAL_LOADING", false);
        });
    });
  },

  UPDATE_FORM: (context, { id, form }) => {
    context.commit("SET_FORM_DETAILS_MODAL_LOADING", true);
    return new Promise((resolve, reject) => {
      AxiosClient.put(`/forms/update/${id}`, form)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        })
        .finally(() => {
          context.commit("SET_FORM_DETAILS_MODAL_LOADING", false);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};

import { Middlewares } from "@/app/shared/services/app-router/middlewares";
import Guard from "vue-router-multiguard";

export default [
  {
    path: "/forms",
    name: "Формы сбора данных",
    component: () => import("./views/formsList.vue"),
    beforeEnter: Guard([Middlewares.authRequired]),
  },
  {
    path: "/forms/users",
    name: "Пользователи с форм",
    component: () => import("./views/formsUsersList.vue"),
    beforeEnter: Guard([Middlewares.authRequired]),
  }
];
